import { jsx } from "react/jsx-runtime";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { Grid } from "@elliemae/ds-grid";
import { useMemo } from "react";
import { useFormState, useWatch } from "react-hook-form";
import _ from "lodash";
import { useSingleComboboxFieldUpdateLogic } from "./utils/fieldUpdateLogic";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
const Nonqmdoclevel = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  ui,
  options,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const singleComboboxFieldUpdateLogic = useSingleComboboxFieldUpdateLogic();
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  const { errors } = useFormState({ control: schemaForm.control });
  const errorMessage = _.get(errors, pathValue)?.message;
  const incomeValue = useWatch({
    control: schemaForm.control,
    name: "income"
  });
  const allComboboxOptions = useMemo(() => {
    let newOpts = options.filter((option) => option.visible);
    if (incomeValue) {
      if (incomeValue.toString() === "100") {
        newOpts = newOpts.filter((option) => Number(option.value) <= 2);
      } else if (incomeValue.toString() === "70") {
        newOpts = newOpts.filter((option) => Number(option.value) > 2);
      }
    }
    return newOpts.map((option) => ({
      dsId: `${option.id}`,
      type: "option",
      label: `${option.text}`,
      value: `${option.value}`
    }));
  }, [options, incomeValue]);
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      name,
      label,
      inputID: name,
      required,
      feedbackMessage,
      hasError: !!errors[id] || !!errorMessage,
      validationMessage: errors[id]?.message?.toString() || errorMessage,
      children: /* @__PURE__ */ jsx(
        DSSingleComboBox,
        {
          ...renderedField,
          allOptions: allComboboxOptions,
          onChange: singleComboboxFieldUpdateLogic(
            renderedField,
            schemaForm,
            id
          ),
          selectedValue: allComboboxOptions.find(
            (option) => option.value === renderedField?.value?.toString()
          ) || null,
          id: pathValue,
          disabled: isDisabled
        }
      )
    }
  ) });
};
export {
  Nonqmdoclevel
};
