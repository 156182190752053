import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { DSAccordion, DSAccordionItem } from "@elliemae/ds-accordion";
import { Grid } from "@elliemae/ds-grid";
import { DSHeader } from "@elliemae/ds-header";
import { DSSeparator } from "@elliemae/ds-separator";
import { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Checkbox } from "./controls/checkbox";
import { Combobox } from "./controls/combobox";
import { Date } from "./controls/date";
import { Fieldgroup } from "./controls/fieldgroup";
import { Loanofficer } from "./controls/loanofficer";
import { Nonqmdoclevel } from "./controls/nonqmdoclevel";
import { Textbox } from "./controls/textbox";
import { Readonly } from "./controls/readonly";
const SchemaSearchForm = ({
  schema,
  schemaForm,
  preview,
  ...rest
}) => {
  const screens = schema.schema.screens[0].screens;
  const sections = schema.schema.screens;
  const rules = schema.schemaRules;
  const [activeAccordions, setActiveAccordions] = useState({});
  const handleAccordionToggle = (value) => {
    setActiveAccordions((prev) => ({
      ...prev,
      [value]: !prev[value]
    }));
  };
  const getComponent = (type, ui, component_id) => {
    const componentIdConfig = {
      "Loan Officer": preview ? Combobox : Loanofficer,
      "Non-QM Doc Level": Nonqmdoclevel
    };
    const widgetConfig = {
      datepicker: Date
    };
    const typeConfig = {
      enum: Combobox,
      boolean: Checkbox,
      field_group: Fieldgroup,
      null: Readonly
    };
    return componentIdConfig[component_id] ?? widgetConfig[ui?.widget] ?? typeConfig[type] ?? Textbox;
  };
  const renderField = (field) => {
    const {
      type,
      ui,
      id,
      label,
      required,
      helptext,
      visible,
      disabled,
      visible_check,
      disable_check
    } = field;
    const { options, component_id, selectMultiple, fields } = field;
    const modelPath = field?.modelPath ?? field?.encompass_ids?.[0]?.modelPath;
    const Component = getComponent(type, ui, component_id);
    return type === "field_group" ? /* @__PURE__ */ jsx(
      Fieldgroup,
      {
        schemaForm,
        rules,
        name: component_id,
        fields,
        visible,
        visibleCheck: visible_check,
        disableCheck: disable_check,
        preview,
        rest
      }
    ) : /* @__PURE__ */ jsx(
      Controller,
      {
        name: modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(
          Component,
          {
            renderedField,
            schemaForm,
            rules,
            id,
            name: component_id,
            label,
            required,
            pathValue: modelPath,
            feedbackMessage: helptext,
            type,
            ui,
            options,
            isMulti: selectMultiple,
            visible,
            disabled,
            fields,
            visibleCheck: visible_check,
            disableCheck: disable_check,
            preview,
            rest
          }
        )
      }
    );
  };
  const renderScreenFields = (screenId, sectionName) => {
    const section = sections.find((section2) => section2.id === screenId);
    const fields = section?.fields?.find((fieldTypes) => fieldTypes.name === sectionName)?.fields ?? [];
    return fields.map((field) => renderField(field));
  };
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(FormProvider, { ...schemaForm, children: screens?.map((screen, index) => /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(
      DSHeader,
      {
        text: sections.find((section) => section.id === screen.id)?.name,
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1,
        mt: "xs",
        mb: "xxs",
        color: void 0,
        fontFamily: void 0,
        height: void 0,
        level: void 0,
        width: void 0
      }
    ),
    /* @__PURE__ */ jsx(Grid, { cols: 12, gutter: "xs", alignItems: "flex-start", children: renderScreenFields(screen.id, "VISIBLE") }),
    /* @__PURE__ */ jsx(Grid, { span: 12, children: /* @__PURE__ */ jsx(DSSeparator, { dashed: true, orientation: "horizontal", position: "initial" }) }),
    /* @__PURE__ */ jsx(Grid, { span: 12, children: /* @__PURE__ */ jsx(
      DSAccordion,
      {
        activeValue: activeAccordions[index] ? index : null,
        onChange: () => handleAccordionToggle(index),
        children: [
          /* @__PURE__ */ jsx(
            DSAccordionItem,
            {
              title: activeAccordions[index] ? "Hide Detailed Entry" : "Open Detailed Entry",
              value: index,
              children: /* @__PURE__ */ jsx(Grid, { cols: ["repeat(12, 0.5fr)"], gutter: "xs", children: renderScreenFields(screen.id, "DETAILED") })
            },
            index
          )
        ]
      }
    ) })
  ] }, screen.id)) }) });
};
export {
  SchemaSearchForm
};
